import api from '@/plugins/api'
import { student as endpoint, course } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'

const store = 'student'

export default {
  state: () => ({
    students: [],
    student: {},

  }),
  actions: {
    async getStudents (context) {
      if (!context.state.students.length) {
        const response = await api.get(endpoint.student)
        context.commit('SET_DATA', { store, state: 'students', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async getStudent (context, id) {
      const response = await api.get(endpoint.studentByPk(id))
      context.commit('SET_DATA', { store, state: 'student', data: response.data })
      return responseValidation(response)
    },
    async updateStudent (context, data) {
      const response = await api.patch(endpoint.studentByPk(data.id),
        { body: data.body })
      const isResult = await responseValidation(response)
      if (isResult) {
        await context.dispatch('getStudent',data.id )
      }
      return isResult
    },
    async setFeedback (context, data) {
      const response = await api.post(course.feedback,
        { body: data.body })
      const isResult = await responseValidation(response)
      if (isResult) {
        await context.dispatch('getCurrentCourseForStudent', true)
      }
      return isResult
    }
  },
  getters: {

  }
}
