export default [
  {
    path: '/admin',
    name: 'admin',
    meta: {
      lang: 'ua'
    },
    redirect: '/admin/courses/current',
    component: () => import('@/views/Admin/Dashboard'),
    children: [
      {
        path: 'courses',
        name: 'admin-courses',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Course/Table'),
      },
      {
        path: 'courses/:id',
        name: 'admin-course',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'courses',
            path: '/admin/courses',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Course/Info'),
      },
      {
        path: 'courses/:courseId/modules/:id',
        name: 'admin-module-info',
        meta: {
          lang: 'ua',
        },
        component: () => import('@/views/Admin/AdminModuleCard'),
      },
      {
        path: 'students',
        name: 'admin-students',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Student/Table'),
      },
      {
        path: 'students/:id',
        name: 'admin-students-info',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'students',
            path: '/admin/students',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Student/Info'),
      },
      {
        path: 'feedbacks',
        name: 'admin-feedback',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Feedback/Table'),
      },
      {
        path: 'feedbacks/:id',
        name: 'admin-feedbacks-info',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'feedbacks',
            path: 'feedbacks',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Feedback/Info'),
      },
      {
        path: 'shop',
        name: 'shop',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Shop/Main'),
      },
      {
        path: 'shop/products',
        name: 'shop-products',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'shop',
            path: '/admin/shop',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Shop/Products/Table.vue'),
      },
      {
        path: 'shop/products/:id',
        name: 'shop-product-info',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'shop',
            path: '/admin/shop',
          }, {
            text: 'products',
            path: '/admin/shop/products',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Shop/Products/Info.vue'),
      }, {
        path: 'shop/categories',
        name: 'shop-categories',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'shop',
            path: '/admin/shop',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Shop/Categories/Table.vue'),
      }, {
        path: 'shop/payments',
        name: 'admin-shop-payments',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'shop',
            path: '/admin/shop',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Shop/Payments/Table.vue'),
      }, {
        path: 'shop/payments/:id',
        name: 'admin-shop-payments-info',
        meta: {
          lang: 'ua',
          breadcrumbs: [{
            text: 'home',
            path: '/admin',
          }, {
            text: 'shop',
            path: '/admin/shop',
          }, {
            text: 'payments',
            path: '/admin/shop/payments',
          }, {
            text: 'current',
            path: '',
          }]
        },
        component: () => import('@/views/Admin/Shop/Payments/Info.vue'),
      },
    ]
  },
]
