const apiVersion = 'api/v1'

export const directories = [
	{ endpoint: `${ apiVersion }/example/`, state: 'example'}
]

export const app = {
	tariff: `${ apiVersion }/tariff/`,
	modules: `${ apiVersion }/module/`,
}

export const student = {
	student: `${ apiVersion }/student/`,
	payment: `${ apiVersion }/payment/`,
	studentByPk: id => `${ apiVersion }/student/${id}/`,
	me: `${ apiVersion }/auth/me/`,
	auth: `${ apiVersion }/auth/login/`,
	password: id => `${ apiVersion }/student/password/${id}/`,
}

export const course = {
  course: `${ apiVersion }/course/`,
  courseByPk: id => `${ apiVersion }/course/${id}/`,
  moduleByPk: id => `${ apiVersion }/module/student/${id}/`,
  moduleAdminByPk: id => `${ apiVersion }/module/${id}/`,
	current: `${ apiVersion }/course/current/`,
	currentForStudent: `${ apiVersion }/course/student/current/`,
  feedback: id => `${ apiVersion }/feedback/${id ? id+'/' : ''}`,
}

export const lesson = {
  lesson: `${ apiVersion }/lesson/`,
  lessonByPk: id => `${ apiVersion }/lesson/${id}/`,
  homeworkByLesson: id => `${ apiVersion }/lesson/${id}/homework/`,
  homeworkByPk: (id, homeworkId) => `${ apiVersion }/lesson/${id}/homework/${homeworkId}/`,
	// current: `${ apiVersion }/course/current/`,
}

export const media = {
  get: id => `${ apiVersion }/media/${id}/`,
  homeworkMedia: id => `${ apiVersion }/media/homework/${id}/`,
  userMedia: id => `${ apiVersion }/media/homework/${id}/user/`,
  seen: id => `${ apiVersion }/media/homework/${id}/`,
  archive: id => `${ apiVersion }/media/homework/archive/${id}/`,
  product: id => `${ apiVersion }/media/product/${id}/`,
}

export const shop = {
  product: `${ apiVersion }/product/`,
  studentProduct: `${ apiVersion }/product/student/`,
  productPublic: `${ apiVersion }/product/all/`,
  productById: id => `${ apiVersion }/product/${ id }/`,
  productArchiveById: id => `${ apiVersion }/product/student/archive/${ id }/`,
  category: `${ apiVersion }/categories/`,
  payments: `${ apiVersion }/payment/`,
  studentPayments: `${ apiVersion }/payment/student/`,
  paymentById: id => `${ apiVersion }/payment/${ id }/`,
}
