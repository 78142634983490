import api from '@/plugins/api'
// import i18n from '@/plugins/vue-i18n'
import { app as endpoint } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'

const store = 'app'


export default {
  state: () => ({
    isLoad: true,
    isMini: false,
    isMicro: false,
    tariffs: [],
    modules: []
  }),
  mutations: {
    SET_LOAD (state, data) {
      state.isLoad = data
    },
    SET_VIEWPORT (state, data) {
      state.isMini = data
    },
    SET_VIEWPORT_MICRO (state, data) {
      state.isMicro = data
    }
  },
  actions: {
    async getTariffs (context) {
      const response = await api.get(endpoint.tariff)
      if (await responseValidation(response)) {
        response.data.forEach(i => {
          i.nameUpdated = `${ i.nameUA } (${ i.priceUsd}$ ~ ${ i.priceUah }UAH)`
          // i.nameUpdated = `${ i18n.t(`tariffs.${i.name}`) } (${ i.priceUsd}$ ~ ${ i.priceUah }UAH)`
        })
        context.commit('SET_DATA', { store, state: 'tariffs', data: response.data })
      }
    },
    async getModules (context) {
      const response = await api.get(endpoint.modules)
      if (await responseValidation(response)) {
        context.commit('SET_DATA', { store, state: 'modules', data: response.data })
      }
    }
  },
  getters: {

  }
}
