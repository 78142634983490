export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      lang: 'ua'
    },
    redirect: '/dashboard/modules',
    component: () => import('@/views/Cabinet/Dashboard'),
    children: [
      {
        path: 'modules',
        name: 'modules',
        meta: {
          lang: 'ua'
        },
        component: () => import('@/views/Cabinet/Modules'),
      },  {
        path: 'modules/:id',
        name: 'module-card',
        meta: {
          lang: 'ua'
        },
        component: () => import('@/views/Cabinet/ModuleCard'),
      },  {
        path: 'shop',
        name: 'student-shop-list',
        meta: {
          lang: 'ua'
        },
        component: () => import('@/views/Cabinet/Shop/Products.vue'),
      }
    ]
  },
]
