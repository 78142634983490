import api from '@/plugins/api'
import { course as endpoint } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'

const store = 'course'

export default {
  state: () => ({
    courses: [],
    course: {},
    current: {},
    module: {},
  }),
  actions: {
    async getCourses (context) {
      if (!context.state.courses.length) {
        const response = await api.get(endpoint.course)
        console.log(response)
        context.commit('SET_DATA', { store, state: 'courses', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async getCurrentCourse (context, reload = false) {
      if (!context.state.current.id || reload) {
        const response = await api.get(endpoint.current)
        console.log(response)
        context.commit('SET_DATA', {store, state: 'current', data: response.data})
        return responseValidation(response)
      }
    },
    async getCurrentCourseForStudent (context, reload = false) {
      if (!context.state.current.id || reload) {
        const response = await api.get(endpoint.currentForStudent)
        console.log(response)

        context.commit('SET_DATA', {store, state: 'current', data: response.data})
        return responseValidation(response)
      }
    },
    async getCourse (context, id) {
      const response = await api.get(endpoint.courseByPk(id))
      console.log(response)
      context.commit('SET_DATA', { store, state: 'course', data: response.data })
      return responseValidation(response)
    },
    async updateCourse (context, data) {
      console.log(data)
      const response = await api.patch(endpoint.courseByPk(data.id),
        { body: data.body })
      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getCourses', true)
        await context.dispatch('getCourse', data.id)
      }
      return isResult
    },
    async getModuleInfo (context, id) {
      const response = await api.get(endpoint.moduleByPk(id))
      const isResult = await responseValidation(response, false)
      if (isResult) {
        // await context.dispatch('getCourses', true)
        // await context.dispatch('getCourse', data.id)
        return response.data
      }
      return false
    },
    async getAdminModuleInfo (context, id) {
      const response = await api.get(endpoint.moduleAdminByPk(id))
      const isResult = await responseValidation(response, false)

      if (isResult) {
        context.commit('SET_DATA', { store, state: 'module', data: response.data })
        return response.data
      }
      return false
    }
  },
  getters: {

  }
}
