<template lang="pug">
  div
    v-data-table(
      :headers="headers"
      :items="items"
      :items-per-page="100"
      :loading="isLoad"
      loading-text="Завантаження..."
      no-results-text="Дані не знайдено"
      no-data-text="Даних немає"
      hide-default-footer
    )

      template(#item.status="{ item }")
        span(:class="statuses.lessonStatus(item.statuses[0].id)?.color") {{ $t(`statuses.${item.statuses[0].name}`) }}

      template(#item.statusPayment="{ item }")
        span(:class="statuses.paymentStatus(item.status)?.color") {{ $t(`statuses.${item.status}`) }}

      template(#item.productType="{ item }")
        span {{ productType(item) }}

      template(#item.isCurrent="{ item }")
        span {{ $t( item.isCurrent ? 'yes' : 'no') }}

      template(#item.paymentStatus="{ item }")
        span {{ $t(`payment.${ item.status }`) }}

      template(#item.isNext="{ item }")
        span {{ $t( item.isNext ? 'yes' : 'no') }}

      template(#item.student="{ item }")
        span {{ item.users[0]?.name }}

      template(#item.buyer="{ item }")
        span {{ item['users.name'] || 'Анонімн' }}

      template(#item.buyerEmail="{ item }")
        span {{ item['users.email'] }}

      template(#item.course="{ item }")
        span {{ item.courses[0]?.name }}

      template(#item.amount="{ item }")
        span {{ item.amount }} ₴

      template(#item.createdAt="{ item }")
        span {{ $moment(new Date(item.createdAt)).format('DD.MM.YYYY HH:mm') }}

      template(#item.isActive="{ item }")
        span(:class="item.isActive ? 'success--text' : 'warning--text'") {{ $t( item.isActive ? 'active' : 'notActive') }}

      template(#item.isPayed="{ item }")
        span(:class="item.isPayed ? 'success--text' : 'warning--text'") {{ $t( item.isPayed ? 'payed' : 'notPayed') }}

      template(#item.link="{ item }")
        router-link(:to="item.link") Перейти

      template(#item.action="{ item }")
        router-link(:to="`${ $route.path }/${ item.id }`")
          v-icon mdi-information-outline


    //v-pagination(
    //  v-if="pageCount >= 2"
    //  v-model="currentPage"
    //  :length="pageCount"
    //  :total-visible="7"
    //  :previous-aria-label="$t('preview')"
    //  :next-aria-label="$t('next')"
    //  aria-label='table-pagination'
    //).mt-7.mb-5
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import statuses from '@/configs/statuses'

export default {
  props: {
    headers: Array,
    items: Array,
    itemsPerPage: Number,
    pageCount: Number,
    detailedRoute: String,
    changePage: Function,
    isLoad: Boolean,
  },
  data () {
    return {
      statuses,
      moment,
      currentPage: Number(this.$route.query.page) || 1,
    }
  },
  computed: {
    ...mapGetters(['']),
  },
  methods: {
    productType (item) {
      if (item.module?.length) return 'Курс (модулі)'
      else if (item.tariff) return 'Курс (тариф)'
      else return 'Товар'
    }
  },
}
</script>
