<template lang="pug">
  v-app-bar(
    flat
    height="50"
    absolute
    elevate-on-scroll
  ).d-flex.align-center.justify-center.mt-4
    v-container.header-procreate.d-flex.align-center.justify-space-between.mt-5.px-6
      div.d-flex.align-center.justify-space-between.mr-4
        //Menu(v-if="isSite")
        img(:src="Logo" width="80px" @click="goTo('/')").ml-4

      // main
      //v-icon(
      //  v-if="!isMini"
      //  @click="goTo('/')"
      //  :class="getActivePage('dashboard') && 'primary--text'"
      //).ml-6 mdi-home
      span(
        v-if="!isMini"
        @click="goTo('/')"
        :class="getActivePage('dashboard') && 'primary--text'"
      ).pointer.bold-500.ml-6 Головна
      // shop
      //v-icon(
      //  v-if="!isMini"
      //  @click="goTo('/shop')"
      //  :class="getActivePage('shop') && 'primary--text'"
      //).ml-6 mdi-shopping
      span(
        v-if="!isMini"
        @click="goTo('/shop')"
        :class="getActivePage('shop') && 'primary--text'"
      ).pointer.bold-500.ml-6 Маркет
      // faq
      //v-icon(
      //  v-if="!isMini"
      //  @click="goTo('/faq')"
      //  :class="getActivePage('faq') && 'primary--text'"
      //).ml-6 mdi-help-circle
      span(
        v-if="!isMini"
        @click="goTo('/student-works')"
        :class="getActivePage('student-works') && 'primary--text'"
      ).pointer.bold-500.ml-6 Роботи учнів
      span(
        v-if="!isMini"
        @click="goTo('/my-works')"
        :class="getActivePage('my-works') && 'primary--text'"
      ).pointer.bold-500.ml-6 Мої роботи
      span(
        v-if="!isMini"
        @click="goTo('/faq')"
        :class="getActivePage('faq') && 'primary--text'"
      ).pointer.bold-500.ml-6 FAQ
      v-spacer



      div(v-if="!isMini").d-flex.align-center.justify-space-between

        // login
        v-card-title.font-16.mr-2 {{ me.name }}
        v-icon(@click="goTo('/me')" ) mdi-account-outline

        CartMini(@goTo="goTo")

        v-icon(v-if="isAutorized" @click="logout" ).ml-6 mdi-exit-to-app


      div(v-if="isMini").d-flex.align-center.justify-end
        v-icon(@click="goTo('/me')" ) mdi-account-outline
        CartMini(@goTo="goTo").mr-6
        v-menu(offset-y)
          template(#activator="{ on, attrs }")
            v-icon(v-bind="attrs" v-on="on") mdi-menu
          v-list.menu
            v-list-item(v-for="(item, index) in menu" :key="index")
              v-list-item-title(@click="goTo(item.link)" :class="getActivePage(item.key) && 'primary--text'")
                v-icon(:class="getActivePage(item.key) && 'primary--text'").mr-2 {{ item.icon }}
                span {{ item.title }}
        //PickerRipple(v-if="isViewPicker"  :isSite="isSite")


</template>

<script>
import { mapState } from 'vuex'
import Logo from '@/assets/img/logo.webp'
import CartMini from '@/views/Site/Shop/Cart/Mini.vue'

import PickerRipple from '@/components/PickerRipple'

export default {
  name: 'Header',
  components: {
    CartMini,
    PickerRipple,
  },
  data () {
    return {
      Logo,
      menu: [{
        link: '/',
        key: 'dashboard',
        icon: 'mdi-home',
        title: 'Головна'
      }, {
        link: '/shop',
        key: 'shop',
        icon: 'mdi-shopping',
        title: 'Маркет'
      }, {
        link: '/student-works',
        key: 'student-works',
        icon: 'mdi-brush',
        title: 'Роботи учнів'
      }, {
        link: '/my-works',
        key: 'my-works',
        icon: 'mdi-palette',
        title: 'Мої роботи'
      }, {
        link: '/faq',
        key: 'faq',
        icon: 'mdi-help-circle',
        title: 'FAQ'
      }, ]
    }
  },
  computed: {
    ...mapState({
      me: state => state.client.me,
      cartLength: state => state.cart.cart?.length
    }),
    lang () {
      return this.$route.meta.lang
    },
    isSite () {
      return this.$route.name ? this.$route?.name.includes('site') : true
    },
    isAutorized () {
      return document.cookie.includes('JWT')
    },
    isViewPicker () {
      return this.$route.meta?.picker
    },
    isMini () {
      return this.$vuetify.breakpoint.width <= 560
    }
  },
  methods: {
    goTo(path) {
      if (path === '/' && this.isAutorized) this.$router.push('/admin')
      else this.$router.push(path)
    },
    getActivePage(page) {
      switch (page) {
        case 'dashboard': return this.$route.path === '/'
        case 'shop':
        case 'student-works':
        case 'my-works':
        case 'faq': return this.$route.path.includes(page) && !this.$route.path.includes('admin')
        default: return false
      }
    },
    logout () {
      document.cookie = `JWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      window.location = '/login'
    }
  }
}
</script>
