// import api from '@/plugins/api'
// import { shop as endpoint} from '@/configs/endpoints'
// import responseValidation from '@/configs/responseValidation'
//
// const store = 'cart'

export default {
  state: () => ({
    cart: [],
  }),
  mutations: {
    SET_PRODUCT_IN_CART (state, product) {
      state.cart = product
    },
    DEL_PRODUCT_IN_CART (state, productId) {
      state.cart = state.cart.filter(i => i.id !== productId)
    },
    DEL_ALL_PRODUCT_IN_CART (state) {
      state.cart = []
    }
  },
  actions: {
    // PRODUCTS


  },
  getters: {

  }
}
