import api from '@/plugins/api'
import { lesson as endpoint, media } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'

const store = 'lesson'

export default {
  state: () => ({
    lessons: [],
    lesson: {}
  }),
  actions: {
    async getLessons (context, reload = false) {
      if (!context.state.lessons.length || reload) {
        const response = await api.get(endpoint.lesson)

        context.commit('SET_DATA', { store, state: 'lessons', data: response.data })
        return responseValidation(response)
      }
      return true
    },
    async getLesson (context, id) {
      const response = await api.get(endpoint.lessonByPk(id))

      context.commit('SET_DATA', { store, state: 'lesson', data: response.data })
      return responseValidation(response)
    },
    async setLesson (context, body) {
      const response = await api.post(endpoint.lesson, { body: body })
      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getAdminModuleInfo', body.module)
        // await context.dispatch('getCurrentCourse', true)
      }
      return isResult
    },
    async updateLesson (context, data) {
      const response = await api.patch(endpoint.lessonByPk(data.id),
        { body: data.body })

      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getAdminModuleInfo', data.body.module)

        // await context.dispatch('getLessons', true)
        // await context.dispatch('getLesson', data.id)
      }
      return isResult
    },


    async setHomework (context, data) {
      const dataMedia = data.body.media
      delete data.body.media
      const response = await api.post(endpoint.homeworkByLesson(data.lesson), { body: data.body })

      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('setMedia', { id: response.data.id, media: dataMedia })
        await context.dispatch('getAdminModuleInfo', data.module)

        // await context.dispatch('getLesson', data.id)
        // await context.dispatch('getCurrentCourse', true)
      }
      return isResult
    },

    async updateHomework (context, data) {
      const response = await api.patch(endpoint.homeworkByPk(data.id, data.body.id),
        { body: data.body })

      const isResult = await responseValidation(response, true)
      if (isResult) {
        await context.dispatch('getAdminModuleInfo', data.module)

        // await context.dispatch('getLesson', data.id)
        // await context.dispatch('getCurrentCourse', true)
      }
      return isResult
    },

    async setMedia (context, data) {
      for (const m of data.media) {
        const formData = new FormData()
        formData.append('name', 'file')
        formData.append('file', m)
        const result = await api.post(media.homeworkMedia(data.id), {
          body: formData
        })

        await responseValidation(result, false)
      }
    },

    async setStudentMedia (context, data) {
      const formData = new FormData()
      formData.append('name', 'file')
      formData.append('file', data.body.media)
      const response = await api.post(media.userMedia(data.id), {
        body: formData
      })
      const isResult = await responseValidation(response, false)
      if (isResult) {
        await context.dispatch('getCurrentCourseForStudent', true)
      }
      return isResult
    }
  },

}
