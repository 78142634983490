import api from '@/plugins/api'
import { student as endpoint } from '@/configs/endpoints'
import responseValidation from '@/configs/responseValidation'

const store = 'client'

export default {
  state: () => ({
    me: {}
  }),
  actions: {
    async setClient (context, body) {
      const response = await api.post(endpoint.student, { body })
      console.log(response)
      const isResult = await responseValidation(response, true)
      console.log(isResult)
      return { status: isResult, code: response.code }
    },

    async setClientPayment (context, body) {
      const response = await api.post(endpoint.payment, { body })
      const isResult = await responseValidation(response, true)
      return { status: isResult, code: response.code, response }
    },

    async setAuth (context, body) {
      const response = await api.post(endpoint.auth, { body })
      // console.log(response)
      const isResult = await responseValidation(response)
      if (isResult) {
        document.cookie = `JWT=${ response.data.token }; samesite=lax; max-age=28800`
      }

      return isResult
    },

    async getClientInfo (context) {
      const response = await api.get(endpoint.me)
      context.commit('SET_DATA', { store, state: 'me', data: response.data })
      return { role: response.data.groups[0].name, lang: response.data.setting?.lang || 'ua' }
    },

    async changePassword (context, data) {
      const response = await api.post(endpoint.password(data.id), { body: data.body })
      return await responseValidation(response, true)
    },

    async editUser (context, data) {
      const response = await api.patch(endpoint.studentByPk(data.id), { body: data.body })
      await context.dispatch('getClientInfo')
      return await responseValidation(response, true)
    },
  },
  getters: {

  }
}
