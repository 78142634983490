<template lang="pug">
v-hover(v-slot="{ hover }")
  div
    v-badge(
      v-if="cartLength"
      :content="cartLength"
      :value="cartLength"
      color="primary"
      transition="slide-x-transition"
      overlap
    )
      v-icon.ml-6 mdi-cart-outline
    v-icon(v-else).ml-6 mdi-cart-outline

    div(v-if="hover").shop
      v-card
        v-card-title Кошик
        v-card-text(v-if="cartLength")
          v-divider.mb-2
          v-row(v-for="c in cart" align="center" justify="space-between" ).pa-0
            v-col(cols="8").py-0
              v-card-title.font-18 {{ c.name }}
              v-card-subtitle.pb-0 {{ c.categoryName }}
            v-col(cols="2").py-0.pr-0 {{ c.price }} ₴
            v-col(cols="1").py-0.pl-0.mr-4
              v-icon(@click="delFromCart(c)") mdi-close
          v-divider.mt-6.mb-2
          div.font-16.text-right
            span.mr-4 Сума:
            span {{ sum }} ₴
          v-card-actions.pt-8
            v-btn(
              color="primary"
              @click="$router.push('/shop/cart')"
            ) Перейти в кошик
        v-card-text(v-else) Поки що пусто
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Mini',
  props: {
    goTo: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      // hover: true
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart.cart
    }),

    cartLength () {
      return this.cart?.length
    },
    sum () {
      return this.cart.reduce((acc, product) => acc + product.price, 0)
    }

  },
  mounted() {
    this.checkCart()
  },
  methods: {
    ...mapMutations(['SET_PRODUCT_IN_CART', 'DEL_PRODUCT_IN_CART']),

    checkCart () {
      this.SET_PRODUCT_IN_CART(JSON.parse(localStorage.getItem('cart')))
    },
    delFromCart (product) {
      console.log(product)

      const existCart = JSON.parse(localStorage.getItem('cart'))
      localStorage.setItem('cart', JSON.stringify(existCart.filter(i => i.id !== product.id)))

      this.DEL_PRODUCT_IN_CART(product.id)
    }
  }
}
</script>

<style lang="sass" scoped>
.shop
  position: absolute
  right: -100px
  & .v-card
    width: 400px
  & .v-btn
    width: 100%
</style>
