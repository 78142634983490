import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/plugins/vue-i18n'
import store from '@/store'
import adminRoutes from '@/router/admin.routes'
import studentRoutes from '@/router/student.routes'
import shopRoutes from '@/router/shop.routes'
Vue.use(Router)

export const routes = [
  {
    path: '*',
    name: 'all',
    redirect: '/404'
  }, {
    path: '/',
    name: 'site',
    meta: {
      lang: 'ua',
      picker: true,
      public: true
    },
    component: () => import('@/views/Site/MainCourse.vue')
  }, {
    path: '/old',
    name: 'site-old',
    meta: {
      lang: 'ua',
      picker: true,
      public: true
    },
    component: () => import('@/views/Site/Main.vue')
  }, {
    path: '/login',
    name: 'login',
    meta: {
      lang: 'ua',
      picker: false,
      public: true
    },
    component: () => import('@/views/Login')
  },
  ...studentRoutes,
  ...adminRoutes,
  ...shopRoutes,
  {
    path: '/me',
    name: 'me',
    meta: {
      lang: 'ua',
      picker: false,
      public: true
    },
    component: () => import('@/views/Me')
  }, {
    path: '/agreement',
    name: 'site-agreement',
    meta: {
      lang: 'ua',
      picker: false,
      public: true
    },
    component: () => import('@/views/Site/Agreement')
  }, {
    path: '/404',
    name: 'not-found',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/Main')
  }, {
    path: '/faq',
    name: 'faq',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/FAQ.vue')
  }, {
    path: '/student-works',
    name: 'student_works',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/StudentWorkFull.vue')
  }, {
    path: '/my-works',
    name: 'my_works',
    meta: {
      lang: 'ua',
      public: true
    },
    component: () => import('@/views/Site/MyWorkFull.vue')
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log(to.meta.public)
  // const isProtectPages = (!to.name.includes('site') && to.name !== 'login' && to.name !== 'not-found')
  const isProtectPages = !to.meta.public

  console.log(isProtectPages)

  if (!isProtectPages) i18n.locale = to.meta.lang
  if (!isProtectPages && document.cookie.includes('JWT')) {
    const data = await store.dispatch('getClientInfo')
    i18n.locale = data.lang
  }

  if (isProtectPages && document.cookie.includes('JWT')) {
    const data = await store.dispatch('getClientInfo')
    console.log(data)
    i18n.locale = data.lang

    if (to.name === 'admin' && data.role === 'admin') next()
    else if (to.name === 'admin' && data.role !== 'admin') next('/dashboard')
    else if (to.name === 'modules' && data.role === 'admin') next('/admin')
    else next()
  }
  else if (to.name === 'login' && document.cookie.includes('JWT') && data.role !== 'admin') next('/dashboard')
  else if (to.name === 'login' && document.cookie.includes('JWT') && data.role === 'admin') next('/admin')
  else if (isProtectPages && !document.cookie.includes('JWT')) next('/login')
  else if (!isProtectPages) next()
})

export default router
