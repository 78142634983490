<template lang="pug">
  v-app(
    theme="dark"
    v-if="!isView"
  ).coming-soon
    ComingSoon
  v-app(
    theme="dark"
    v-else="isView"
  )
    Header
    router-view
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import Header from '@/components/Header'
import ComingSoon from '@/views/Site/ComingSoon.vue'
// import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    ComingSoon,
    // Footer,
  },
  data () {
    return {
      drawer: null,
      isContentBoxed: false,
    }
  },
  computed: {
    ...mapState({
      isLoadApp: state => state.app.isLoad
    }),
    isView () {
      // return false
      return location.host !== 'znikson.art' || localStorage.getItem('access') === 'primary'
    }
  },
  beforeMount() {
    if (this.$vuetify.breakpoint.width <= 560) this.SET_VIEWPORT(true)
    if (this.$vuetify.breakpoint.width <= 400) this.SET_VIEWPORT_MICRO(true)
  },
  mounted () {
    this.getTariffs()
    this.getModules()
  },
  methods: {
    ...mapActions(['getTariffs', 'getModules']),
    ...mapMutations(['SET_VIEWPORT', 'SET_VIEWPORT_MICRO'])
  }
};
</script>
